import React, { useState, useEffect, Fragment, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { configStyle, positionDiv } from '../services/configStyle'
import { hayExcepcion, status, statusError } from '../services/data';
import Ayuda from './ayuda';


const PreparacionComprobante = () => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [showCancel, setShowCancel] = useState(false)
    const [apiKey, setApiKey] = useState('')
    const { ruta } = useParams();
    const [showHelp, setShowHelp] = useState(false);
    const divFooterRef = useRef(null);

    useEffect(() => {

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        status("preparacion_id", "Preparacion ID");

        let divFooter = divFooterRef.current;
        divFooter.style.position = positionDiv(window.innerWidth, window.innerHeight);
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const statusE = () => {
        let data = "Cancelado"
        statusError("Preparacion ID", data, "cancelado")
        setTimeout(() => {
            history.push("/" + (ruta !== "pdf_video_token" ? apiKey : `pdf_token?uuid=${localStorage.getItem("uuidUser")}&idOtorgante=${localStorage.getItem("idOtorgante")}&fecha=${localStorage.getItem("fechavigencia")}`))
        }, 300);
    }

    return (
        <Fragment>
            {(showCancel) ?
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancelar proceso</h5>
                                <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => setShowCancel(false)} className="btn btn-secondary" data-dismiss="modal">NO</button>
                                <button type="button" onClick={e => statusE()} className="btn btn-raised btn-primary main_bg_color">SI</button>
                            </div>
                        </div>
                    </div>
                </div> : ("")}
            <div className="main_gradient">
                <Header ruta="preparacion_identificacion" show={() => setShowHelp(true)} />
                <div>
                    <div className="main_text_container">
                        <h1 className="animate__animated">Envía tu comprobante de domicilio</h1>
                        <p className="animate__animated">Puedes capturar tu <b>recibo de luz</b>, <b>teléfono fijo</b>, <b>internet</b>, o <b>gas</b>.</p>
                    </div>
                    <div className="main_icon_container">
                        <img src="images/bnw/doc_check_b.svg" alt="" />
                    </div>
                </div>
                <div className="div_bottom" ref={divFooterRef}>
                    <div className="action_buttons">
                        <Link to={{
                            pathname: "/comprobante",
                            state: { passport: false }
                        }} className="btn btn-raised btn-primary forcewidth100 main_bg_color">
                            CAPTURAR</Link>
                        <button onClick={e => setShowCancel(true)} className="btn btn-primary forcewidth100 main_color">
                            CANCELAR</button>

                    </div>
                    <Footer />
                </div>
            </div>
            <div className={(showCancel ? "modal-backdrop show" : "")}></div>
            {showHelp ? <Ayuda hide={() => setShowHelp(false)} /> : ""}
        </Fragment>
    )

}


export default PreparacionComprobante